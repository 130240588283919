import '../polyfills';
import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { createGlobalStyle, ThemeProvider } from 'styled-components';
import { Roboto_Slab, Inter } from 'next/font/google';
import { LiveChatLoaderProvider, HelpScout } from 'react-live-chat-loader'

import '@/styles/global.css';

import { ErrorBoundary } from '@/bugsnag'
import { UIStateProvider } from '@/components/ui-state-provider';
import themes from '@/themes';
import { initializeTracking, trackPageView } from '@/tracker';
import Toast from '@/components/toast';
import DefaultHead from '@/components/default-head';
import Navbar from '@/components/navigation/navbar';
import NewsletterSignup from '@/components/newsletter-signup';
import Footer from '@/components/footer';
import Error from '@/pages/_error';

const roboto = Roboto_Slab({
  subsets: ['latin'],
  variable: '--font-roboto-slab',
  weight: '900'
});

const inter = Inter({
  subsets: ['latin'],
  variable: '--font-inter'
});

const GlobalStyles = createGlobalStyle`
  *, *::before, *::after {
    box-sizing: border-box;
  }

  :root {
    background-color: ${props => props.theme.colors.background.primary};
    color: ${props => props.theme.colors.typography.primary};
    font-family: ${props => props.theme.fonts.body};
    font-size: ${props => props.theme.fontSizes[2]}px;
    line-height: 1.6;
    max-width: 100vw;
    overflow-x: hidden;
  }
`;

const getToastMessage = (code, alias) => {
  switch (code) {
    case 'user-not-found':
      return `${alias ? `The @${alias}` : 'The user'} account doesn't exist.`;
    case 'missing-info':
      return 'Some required information, like your name and email, is missing.';
  }
};

function App ({ Component, pageProps }) {
  const theme = 'light';
  const router = useRouter();
  const [showToast, setShowToast] = useState(false);

  const getToastProps = () => {
    const toastProps = {};

    if (router.query.error) {
      toastProps.type = 'error';
      toastProps.message = getToastMessage(router.query.error, router.query.alias);
    }

    if (router.query.funded && [true, 'true'].includes(router.query.funded)) {
      toastProps.type = 'success';
      toastProps.message = `The payment to fund the @${alias} account has been processed. It may take a few minutes to reflect in the account balance.`;
    }

    return toastProps;
  };

  useEffect(() => {
    let timeout;

    if (showToast) {
      timeout = setTimeout(() => {
        setShowToast(false);
        router.replace('/');
      }, 5000);
    }

    return () => clearTimeout(timeout);
  }, [showToast, router]);

  useEffect(() => {
    initializeTracking();
    const handleRouteChange = url => trackPageView(url);
    router.events.on('routeChangeComplete', handleRouteChange);

    if (router.query.error || router.query.success) {
      setShowToast(true);
    }

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events, router.query]);

  useEffect(() => {
    document.querySelector('html').setAttribute('class', pageProps.htmlClassName || '');
  });

  return (
    <ErrorBoundary FallbackComponent={Error}>
      <ThemeProvider theme={themes.find(t => t.name === theme)}>
        <LiveChatLoaderProvider providerKey="cf231c84-2a1d-4b6b-a6f0-79b607eff0cc" provider="helpScout">
          <UIStateProvider>
            {showToast && <Toast {...getToastProps()} />}
            <DefaultHead />
            <GlobalStyles />
            {/* <OldNavbar /> */}
            <Navbar />
            <div className={`${roboto.variable} ${inter.variable} pt-[env(safe-area-inset-top,_0)]`}>
              <Component {...pageProps} />
            </div>
            <NewsletterSignup />
            <Footer />
            <HelpScout />
          </UIStateProvider>
        </LiveChatLoaderProvider>
      </ThemeProvider>
    </ErrorBoundary>
  );
}

export default App;
