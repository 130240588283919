import { useEffect, useRef } from 'react';
import Link from '@/components/link';
import tw from 'tailwind-styled-components';

const IFrame = tw.iframe`
  border-none
  m-0
  h-0
  outline-none
  w-full
`;

const NewsletterSignup = () => {
  const iframeRef = useRef(null);

  useEffect(() => {
    const iframe = iframeRef.current;

    if (iframe && iframe.iFrameResizer) {
      iframe.iFrameResizer.resize();
    }
  }, []);

  return (
    <div className="snap-start relative z-10">
      <div className="max-w-7xl mx-auto px-4 py-12 lg:py-16 xl:px-0">
        <div className="py-10 px-6 bg-brand-primary rounded-3xl sm:py-16 sm:px-12 lg:p-20 lg:flex lg:items-center">
          <div className="lg:w-0 lg:flex-1">
            <h2 className="text-3xl font-extrabold tracking-tight text-white">Sign up for our newsletter</h2>
            <p className="mt-4 max-w-3xl text-lg text-indigo-100">
              Stay up to date with important announcements, product updates and sweet deals. 🍭
            </p>
          </div>
          <div className="mt-12 sm:w-full sm:max-w-md lg:mt-0 lg:ml-8 lg:flex-1 mx-auto">
            <IFrame
              ref={iframeRef}
              data-w-type="embedded"
              frameborder="0"
              scrolling="no"
              marginHeight="0"
              marginWidth="0"
              src="https://sstts.mjt.lu/wgt/sstts/xw05/form?c=4dd66abf"
            />
            <script async type="text/javascript" src="https://app.mailjet.com/pas-nc-embedded-v1.js" />
            <p className="mt-3 text-sm text-indigo-100">
              We care about the protection of your data. Read our{' '}
              <Link href="/policies/privacy" className="text-white underline">
                Privacy Policy.
              </Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  )
};

export default NewsletterSignup;
