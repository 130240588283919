import { AlertOctagon, CheckCircle } from 'styled-icons/feather';
import { Paragraph } from '@bidalihq/ui';
import tw from 'tailwind-styled-components';

import { colors } from '@/themes';

const Container = tw.div`
  items-center
  flex
  left-1/2
  bg-white
  rounded-xl
  shadow-xl
  p-4
  pointer-events-none
  fixed
  top-8
  -translate-x-1/2
  z-50
`;

const Toast = ({ message, type }) => {
  return (
    <Container type={type}>
      {type === 'error' && <AlertOctagon color={colors.status.danger} size={22} strokeWidth={2} />}
      {type === 'success' && <CheckCircle color={colors.status.success} size={22} strokeWidth={2} />}
      <Paragraph className="ml-2">{message}</Paragraph>
    </Container>
  );
};

export default Toast;
