import { Button as BidaliButton } from '@bidalihq/ui';
import Link from 'next/link';

import { trackEvent, fbTrackEvent } from '@/tracker';

/**
* @typedef {import('@bidalihq/ui/dist/components/button/button').ButtonProps & {
*   to?: string,
*   gaLabel?: string
* }} ButtonProps
* @param {ButtonProps} props - Component props
* @returns {JSX.Element} - Button component
*/
const Button = ({ children, to, gaLabel, ...props }) => {
  const target = typeof to === 'string' && (to.includes('bidali.com') || to.startsWith('/'))
    ? '_self'
    : '_blank';

  const handleClick = event => {
    const params = {
      category: 'button',
      action: 'clicked',
      label: gaLabel
    };

    trackEvent(params);
    fbTrackEvent(gaLabel, params);

    if (typeof to !== 'string' && typeof props.onClick === 'function') {
      props.onClick(event);
    }
  };

  if (typeof to === 'string') {
    return (
      <Link
        href={to}
        passHref
        target={target}
        legacyBehavior
      >
        <BidaliButton {...props} $as="a" onClick={handleClick}>
          {children}
        </BidaliButton>
      </Link>
    );
  }

  return (
    <BidaliButton {...props} onClick={handleClick}>
      {children}
    </BidaliButton>
  );
};

export default Button;
