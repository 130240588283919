export const fonts = {
  body: '-apple-system, BlinkMacSystemFont, Helvetica, Arial, sans-serif',
  // heading: 'Inter, -apple-system, BlinkMacSystemFont, Helvetica, Arial, sans-serif',
  monospace: 'Menlo, monospace',
};

export const fontSizes = [12, 14, 16, 20, 24, 32, 48, 64, 72];

export const colors = {
  background: {
    card: '#FFFFFF',
    inverted: '#4C4C71',
    primary: '#FFFFFF'
  },
  brand: {
    accent: '#E4517F',
    primary: '#4B4DF1',
    alt: '#F4F389'
  },
  typography: {
    primary: '#4C4C71',
    subtle: '#8282A1',
    verySubtle: 'rgba(0, 0, 0, 0.30)',
    inverted: 'rgba(255, 255, 255, 0.9)',
    invertedSubtle: 'rgba(255, 255, 255, 0.5)'
  },
  status: {
    danger: '#EB3449',
    info: '#32C5FF',
    success: '#2DD8A4',
    warning: '#F2C94C'
  }
};

export const gradients = {
  multiColor: `
    linear-gradient(25deg, rgb(235, 230, 251), rgba(235, 230, 251, 0) 30%) repeat scroll 0% 0%,
    linear-gradient(-100deg, rgb(226, 250, 213), rgba(226, 250, 213, 0) 25%) repeat scroll 0% 0%,
    linear-gradient(145deg, rgb(245, 232, 218), rgb(250, 237, 253) 16%, rgb(250, 237, 207)) repeat scroll 0% 0%
  `
};

const lightTheme = {
  name: 'light',
  fonts,
  fontSizes,
  text: {
    primary: {
      color: colors.typography.subtle
    }
  },
  colors
};

const darkTheme = {
  ...lightTheme,
  name: 'dark',
  colors: {
    ...colors,
    background: {
      primary: '#121212',
      inverted: colors.background.primary,
      card: '#232323'
    },
    typography: {
      primary: 'rgba(255, 255, 255, 0.87)',
      secondary: '#F9F9F9',
      subtle: '#B2B3C4',
      inverted: '#0A0A0A',
      disabled: '#777'
    }
  }
};

const themes = [lightTheme, darkTheme];

export default themes;
