import { useEffect } from 'react';
import { useUIContext } from '@/components/ui-state-provider';

export default function useNavbarBackground (color = 'transparent', styles = {}) {
  const { background, setBackground, navBarStyles, setNavBarStyles } = useUIContext();
  const stringifiedStyles = JSON.stringify(styles);

  useEffect(() => {
    setBackground(color);
    setNavBarStyles(styles);

    return () => {
      setBackground('transparent');
      setNavBarStyles({});
    }
  }, [color, setBackground, stringifiedStyles, setNavBarStyles]); // eslint-disable-line react-hooks/exhaustive-deps

  return { background, navBarStyles, setBackground, setNavBarStyles };
}
