import tw from 'tailwind-styled-components';
import clsx from 'clsx';
import { animated } from 'react-spring';

import MaxWidth from '@/components/max-width';

const AngleBottom = tw.svg`
  -bottom-[1px]
  h-12
  left-0
  absolute
  w-full
  z-0

  md:h-20
  lg:h-48
`;

const AngleTop = tw.svg`
  h-12
  left-0
  absolute
  top-0
  w-full
  z-0

  md:h-20
  lg:h-48
`;

const Container = tw(animated.div)`
  block
  relative
  w-full
  max-w-[100vw]
  px-0
  py-12

  md:py-20
  print:py-12
`;

const Content = tw(MaxWidth)`
  h-full
  px-4
  relative
  z-[1]

  xl:px-0
`;

const Section = ({
  angleTop,
  angleTopReverse,
  angleBottom,
  angleBottomReverse,
  backgroundColor = 'transparent',
  center,
  className,
  children,
  style = {},
  ...props
}) => {
  const backgroundStyle = {
    background: backgroundColor,
    ...style
  };

  const angleClassNames = clsx({
    '[transform:rotateY(180deg)]': angleBottomReverse || angleTopReverse
  });

  const contentClassNames = clsx({
    'items-center flex flex-col text-center': center
  });

  return (
    <Container {...props} className={className} style={backgroundStyle}>
      {(angleTop || angleTopReverse) &&
        <AngleTop className={angleClassNames} width="400" height="40" viewBox="0 0 400 40" preserveAspectRatio="none">
          <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <polygon id="Path" fill="white" points="0 0 400 0 400 40"></polygon>
          </g>
        </AngleTop>
      }
      <Content className={contentClassNames}>{children}</Content>
      {(angleBottom || angleBottomReverse) &&
        <AngleBottom className={angleClassNames} width="400" height="40" viewBox="0 0 400 40" preserveAspectRatio="none">
          <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <polygon id="Path-2" fill="white" points="0 0 0 40 400 40"></polygon>
          </g>
        </AngleBottom>
      }
    </Container>
  );
};

export default Section;
