import { createContext, useContext, useMemo, useState } from 'react';

export const Context = createContext({ backgroundColor: 'transparent', showFooter: true });
export const useUIContext = () => useContext(Context);
export const UIStateProvider = ({ backgroundColor = 'transparent', children, showFooter = true }) => {
  const [background, setBackground] = useState(backgroundColor);
  const [navBarStyles, setNavBarStyles] = useState({});
  const [footer, setFooter] = useState(showFooter);
  const stringifiedStyles = JSON.stringify(navBarStyles);

  const value = useMemo(() => {
    return {
      background,
      setBackground,
      navBarStyles,
      setNavBarStyles,
      footer,
      setFooter
    };
  }, [background, footer, stringifiedStyles]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Context.Provider value={value}>
      {children}
    </Context.Provider>
  );
};
