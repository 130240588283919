import React from 'react'
import ErrorPage from 'next/error'
import { SadTear } from 'styled-icons/fa-regular';
import tw from 'tailwind-styled-components';
import { Title } from '@bidalihq/ui';

import Bugsnag from '@/bugsnag';
import Section from '@/components/section';
import Button from '@/components/button';

const SadFaceIcon = tw(SadTear)`
  text-brand-primary
  mb-16
`;

export default class Page extends React.Component {
  static async getInitialProps (ctx) {
    if (ctx.err) {
      Bugsnag.notify(ctx.err);
    }
    return ErrorPage.getInitialProps(ctx);
  }

  render () {
    return (
      <Section>
        <Title>Oops! Something went wrong.</Title>
        <SadFaceIcon size={100} />
        <Button to="/">Go back home</Button>
      </Section>
    );
  }
}
