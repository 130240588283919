import tw from 'tailwind-styled-components';
import { Facebook, Linkedin, Twitter, Instagram, Github } from 'styled-icons/fa-brands';
import { Grid, Column, Paragraph } from '@bidalihq/ui';

import Link from '@/components/link';
import config from '@/config';
import useFooter from '@/hooks/use-footer';
import MaxWidth from '@/components/max-width';

const Container = tw.footer`
  bg-gray-800
  snap-end
  relative
  z-10
`;

const Title = tw(Paragraph)`
  text-sm
  font-semibold
  text-gray-400
  tracking-wider
  uppercase
  mt-0
  md:mt-0
`;

const List = tw.ul`
  mt-4
  space-y-4
`;

const FooterLink = tw(Link)`
  no-underline
  hover:underline
  text-base
  text-gray-300
  hover:text-white
`;

const SocialLinks = tw.div`
  mt-8
  border-t
  border-gray-700
  pt-8
  md:flex
  md:items-center
  md:justify-between
`;

const DisclaimerContainer = tw.div`
  pb-12
`;

const Legal = tw(Paragraph)`
  text-xs
  md:text-xs
  mb-4
  text-gray-500
`;

const navigation = {
  products: [
    { name: 'Bidali App', href: '/app', title: 'Pay, save, earn. All in one place.' },
    { name: 'Spend', href: 'https://spend.bidali.com', title: 'Buy gift cards and mobile airtime' },
    // { name: 'Payments', href: '/business/payments', title: 'Low-cost digital currency payments' },
    // { name: 'Bidali POS', href: '/business/pos', title: 'Bidali Point of Sale App' },
    { name: 'Branded Currency', href: '/business/branded-currency', title: 'Next generation stored value' },
    { name: 'Rewards & Incentives', href: '/business/rewards', title: 'Improved customer acquisition, loyalty and retention' },
    // { name: 'Treasury', href: '/business/treasury', title: 'Corporate high yield treasury' }
  ],
  solutions: [
    { name: 'Businesses', href: '/solutions/businesses', title: 'Solutions for Businesses' },
    // { name: 'Government', href: '/solutions/government', title: 'Solutions for Government' },
    // { name: 'Banks', href: '/solutions/banks', title: 'Solutions for Banks & Financial Institutions' },
    // { name: 'Remittance', href: '/solutions/remittance', title: 'Remittance and cross border payments' },
    // { name: 'Humanitarian Aid', href: '/solutions/humanitarian-aid', title: 'Solutions for humanitarian aid agencies' }
  ],
  resources: [
    { name: 'Support', href: 'https://support.bidali.com', title: 'Bidali help and support' },
    { name: 'Security', href: '/policies/security', title: 'Bidali Security policy' },
    { name: 'Careers', href: '/careers', title: 'Careers at Bidali' },
    { name: 'About Us', href: '/about', title: 'Learn more about Bidali, our team and our mission' },
    { name: 'Blog', href: '/blog', title: 'The Bidali Blog' },
    { name: 'Brand Assets', href: '/branding', title: 'Our brand logos and usage guidelines' },
    { name: 'Press Kit', href: '/press', title: 'Our ready to go press kit' }
  ],
  legal: [
    { name: 'Terms', href: '/policies/terms/', title: 'Bidali terms of service' },
    { name: 'Privacy', href: '/policies/privacy/', title: 'Bidali privacy policy' },
    { name: 'Security', href: '/policies/security/', title: 'Bidali security policy' },
    { name: 'Cookies', href: '/policies/cookies/', title: 'Bidali cookie policy' },
    { name: 'AML & KYC', href: '/policies/aml/', title: 'Bidali AML and KYC policy' }
  ],
  social: [
    {
      name: 'Facebook',
      href: 'https://www.facebook.com/bidalihq',
      icon: (props) => (
        <Facebook {...props}/>
      ),
    },
    {
      name: 'Instagram',
      href: 'https://www.instagram.com/bidalihq',
      icon: (props) => (
        <Instagram {...props}/>
      ),
    },
    {
      name: 'Twitter',
      href: 'https://twitter.com/bidalihq',
      icon: (props) => (
        <Twitter {...props}/>
      ),
    },
    {
      name: 'LinkedIn',
      href: 'https://www.linkedin.com/company/bidali',
      icon: (props) => (
        <Linkedin {...props}/>
      ),
    },
    {
      name: 'GitHub',
      href: 'https://github.com/bidalihq',
      icon: (props) => (
        <Github {...props}/>
      ),
    }
  ],
}

const Disclaimer = () => {
  return (
    <DisclaimerContainer aria-labelledby="disclaimer-heading">
      <h3 id="disclaimer-heading" className="sr-only">Disclaimer</h3>
      <Legal>
        <sup>†</sup>Bidali Cash is a closed-loop, prepaid, stored-value instrument, similar to a digital e-gift card. Bidali Cash is issued solely by Bidali and
        represents pre-purchased credit at Bidali that is only redeemable for goods and services offered on the Bidali Platform. Bidali Cash is denominated in official government
        issued currency (such as CAD or USD), however Bidali Cash has no cash value and is not redeemable for cash. Bidali Cash is issued as a closed-loop cryptographically secured digital
        token on a distributed ledger in order to improve transparency in liabilities, aid in audits, and reduce fraud, loss, and theft risk. These digital tokens are limited in their functionality
        and only issued to white listed Bidali accounts. As such, they cannot be transferred, exchanged or used outside of the Bidali platform.
      </Legal>
      <Legal className='font-semibold uppercase'>
        Your Bidali Cash balance reflects a purchase of prepaid platform credit or customer appreciation rewards issued solely by Bidali purely for the purpose of redeeming them for goods and
        services offered on the Bidali Services. Your Bidali Cash balance is a liability of Bidali just the same as an electronic gift card or voucher and is not for sale nor tradeable or exchangeable
        for other digital assets, securities, or derivatives. As such, you should have no expectation of profit by purchasing or being gifted Bidali Cash because it is not usable outside of the
        Bidali Services and is dependent on the ability for Bidali to continue business operations. Any Bidali Cash balance increase is a voluntary customer appreciation cashback reward issued solely
        by Bidali from its own corporate balance sheet, in its sole discretion, based on your usage of the Bidali Services, which is subject to change at any time.
      </Legal>
      <Legal>
        Bidali is not a deposit taking institution so your Bidali Cash balance linked to your Bidali account is not a deposit account, which means that your Bidali Cash account balance will not be protected
        by the Canadian Deposit Insurance Corporation (&quot;CDIC&quot;), Bermuda Deposit Insurance Corporation (&quot;BDIC&quot;), Federal Deposit Insurance Corporation (&quot;FDIC&quot;) in the United States,
        or any other deposit insurance program in your respective country. By using the Bidali Services you acknowledge that the purchase of Bidali Cash is a commercial transaction whereby you are purchasing
        non-refundable stored value platform credit from Bidali, similar to a gift card or voucher, for use on the Bidali platform. As such, your purchase of Bidali Cash should not be misconstrued for a bank deposit, loan, or investment.
      </Legal>
      <Legal>
        For more details on your Bidali Cash balance and rewards please review our <Link className="text-white" href="/policies/terms">Terms of Use</Link>.
      </Legal>
      <Legal>
        The merchant, as well as its owners and affiliates, for whom gift cards, mobile airtime, and other products and services are available within the Bidali Services (the &quot;Brand&quot;) are not
        sponsors of Bidali, the Bidali Services, or otherwise affiliated with Bidali other than through a reseller, affiliate marketing relationship. Brand logos, trademarks, copyrights, and
        any other identifying marks attached to their gift cards or products sold by Bidali are solely owned by the Brand. For complete product terms and conditions, please review our&nbsp;
        <Link className="text-white" href="/policies/terms">Terms of Use</Link> as well as each respective Brand&apos;s terms and conditions linked in each product&apos;s details.
      </Legal>
      <Legal>
        The Bidali platform and intellectual property is developed by Bidali Inc. (Canada) and licensed to its wholly owned subsidiary Bidali International Ltd. (Bermuda). Bidali International Ltd. is the operating entity
        with whom you have a commercial agreement with when you interact with the Bidali Services. Bidali International Ltd. is a registered Foreign Money Services Business (FMSB) with FINTRAC with registration #:
        <Link className="text-white" href="https://www10.fintrac-canafe.gc.ca/msb-esm/public/detailed-information/msb-details/7b226d73624f72674e756d626572223a3136383637392c227072696d617279536561726368223a7b226f72674e616d65223a22626964616c69222c2273656172636854797065223a317d7d/">M22361482</Link>&nbsp;
        and is anticipated to be licensed under the Bermuda Digital Asset Business Act (DABA), pending approval by the Bermuda Monetary Authority (BMA).
      </Legal>
    </DisclaimerContainer>
  );
}

const Footer = () => {
  const { footer } = useFooter();

  if (!footer) return null;

  return (
    <Container aria-labelledby="footer-heading">
      <h2 id="footer-heading" className="sr-only">Footer</h2>
      <MaxWidth className="py-12 px-4 sm:px-6 lg:py-16 xl:px-0">
        <Disclaimer />
        <Grid columns={[1, 2, 6]}>
          <Column>
            <Title $as="h3">Products</Title>
            <List role="list">
              {navigation.products.map((item) => (
                <li key={item.name}>
                  <FooterLink href={item.href} title={item.title}>
                    {item.name}
                  </FooterLink>
                </li>
              ))}
            </List>
          </Column>
          {/* <Column>
            <Title $as="h3">Solutions</Title>
            <List role="list">
              {navigation.solutions.map((item) => (
                <li key={item.name}>
                  <FooterLink href={item.href} title={item.title}>
                    {item.name}
                  </FooterLink>
                </li>
              ))}
            </List>
          </Column> */}
          <Column>
            <Title $as="h3">Resources</Title>
            <List role="list">
              {navigation.resources.map((item) => (
                <li key={item.name}>
                  <FooterLink href={item.href} title={item.title}>
                    {item.name}
                  </FooterLink>
                </li>
              ))}
            </List>
          </Column>
          <Column>
            <Title $as="h3">Legal</Title>
            <List role="list">
              {navigation.legal.map((item) => (
                <li key={item.name}>
                  <FooterLink href={item.href} title={item.title}>
                    {item.name}
                  </FooterLink>
                </li>
              ))}
            </List>
          </Column>
        </Grid>
        <SocialLinks>
          <div className="flex space-x-6">
            {navigation.social.map((item) => (
              <FooterLink key={item.name} href={item.href} className="text-gray-400 hover:text-gray-300">
                <span className="sr-only">{item.name}</span>
                <item.icon className="h-6 w-6" aria-hidden="true" />
              </FooterLink>
            ))}
          </div>
          <p className="mt-8 text-base text-gray-400 md:mt-0">
            &copy; 2018 - {new Date().getFullYear()} Bidali Inc. All rights reserved.
          </p>
          <p className="mt-2 text-base text-gray-400">
            Website Version: v{`${config.appVersion}`}
          </p>
        </SocialLinks>
      </MaxWidth>
    </Container>
  )
};

export default Footer;
