import React from 'react';
import numeral from 'numeral';
import merge from 'lodash.merge';

export const SYMBOLS = {
  BMD: '$',
  EUR: '€',
  GBP: '£',
  INR: '₹',
  RUB: '₽',
  CAD: '$',
  AUD: '$',
  USD: '$',
  MXN: '$',
  ARS: '$'
};

export const valueOrNull = value => {
  return value !== '' ? value : null;
};

export const boolValue = value => {
  return value === 'true';
};

export const randomInteger = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const loadScript = (src, callback) => {
  const script = document.createElement('script');
  let isReady = false;
  let target;

  script.type = 'text/javascript';
  script.src = src;
  script.onload = script.onreadystatechange = function () {
    if (!isReady && (!this.readyState || this.readyState == 'complete')) {
      isReady = true;
      callback();
    }
  };

  target = document.getElementsByTagName('script')[0];
  target.parentNode.insertBefore(script, target);
};

export const objectToQueryString = obj => {
  if (!Object.keys(obj).length) return '';

  const QS = Object.keys(obj)
    .map(key => {
      const value = obj[key];
      return value && value !== 'undefined' && `${key}=${encodeURIComponent(value)}`;
    })
    .filter(param => !!param)
    .join('&');

  return `?${QS}`;
};

export const checkCurrencyAvailability = currency => {
  const supportedCurrencies = ['BMD', 'CAD', 'USD'];

  if (supportedCurrencies.includes(currency.toUpperCase())) {
    return currency.toUpperCase();
  }

  return 'USD';
};

export const formatForCurrency = ({ amount, currency }) => {
  const formattedAmount = numeral(amount).format(`0,0[.]00`);
  return `${SYMBOLS[currency] || ''}${formattedAmount} ${currency}`;
};

export const sx = (Component, sx) => {
  return function sxComponent (props) {
    const styles = typeof sx === 'function'
      ? merge(sx(props), props.sx)
      : merge(sx, props.sx);

    return <Component {...props} sx={styles} />;
  }
};

/**
 * Tailwind Template String
 * @param {TemplateStringsArray} string
 * @return {String}
 */
export function twString (string) {
  return string.join(' ');
}
