import { forwardRef } from 'react';
import NextLink from 'next/link';
import clsx from 'clsx';

const Link = forwardRef(({ children, className, onClick, href, ...rest }, ref) => {
  const classNames = clsx('text-brand-primary no-underline hover:underline', className);

  return (
    <NextLink
      className={classNames}
      href={href}
      onClick={onClick}
      ref={ref}
      target={href.includes('bidali.com') || href.startsWith('/') ? '_self' : '_blank'}
      {...rest}
    >
      {children}
    </NextLink>
  );
});

Link.displayName = 'Link';

export default Link;
