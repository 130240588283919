import tw from 'tailwind-styled-components';
import { Dropdown, Grid, List } from '@bidalihq/ui';
import Image from 'next/image';

import { Bars3 } from '@styled-icons/heroicons-outline/Bars3';
import { Bookmark } from '@styled-icons/heroicons-outline/Bookmark';
import { Business } from '@styled-icons/material-outlined/Business';
import { ChevronDown } from '@styled-icons/heroicons-outline/ChevronDown';
import { Currency } from '@styled-icons/remix-fill/Currency';
import { DevicePhoneMobile } from '@styled-icons/heroicons-outline/DevicePhoneMobile';
import { Gift } from '@styled-icons/heroicons-outline/Gift';
// import { GlobeAmericas } from '@styled-icons/heroicons-outline/GlobeAmericas';
// import { Heart } from '@styled-icons/heroicons-outline/Heart';
import { InformationCircle } from '@styled-icons/heroicons-outline/InformationCircle';
import { Lifebuoy } from '@styled-icons/heroicons-outline/Lifebuoy';
// import { Payments } from '@styled-icons/material-outlined/Payments';
import { Phone } from '@styled-icons/heroicons-outline/Phone';
// import { Play } from '@styled-icons/heroicons-outline/Play';
import { Reward } from '@styled-icons/fluentui-system-regular/Reward';
import { ShieldCheck } from '@styled-icons/heroicons-outline/ShieldCheck';
// import { XMark } from '@styled-icons/heroicons-outline/XMark';

import Link from '@/components/link';
import Button from '@/components/button';
import useNavbarBackground from '@/hooks/use-navbar-background';
import MaxWidth from '@/components/max-width';
import { colors } from '@/themes';

const Logo = tw(Image)`
  h-8
  w-auto
  sm:h-8
`;

const StyledLink = tw(Link)`
  hover:no-underline
`;

const Container = tw.div`
  snap-start
  snap-always
  relative
  transform-gpu
  will-change-transform
  h-20
  flex
  items-center
  z-[9000]
`;

const Content = tw(MaxWidth)`
  flex
  items-center
  px-4
  py-5
`;

const NavItem = tw.a`
  cursor-pointer
  font-medium
  grouprounded-md
  hover:text-gray-900
  inline-flex
  items-center
  text-base
  text-gray-500
`;

const Heading = tw.h4`
  text-sm
  text-slate-500
  uppercase
`;

const DesktopNav = tw.div`
  flex-1
  h-full
  hidden
  justify-end

  lg:flex
  lg:gap-10
  lg:items-center
`;

const MobileNav = tw.div`
  flex
  flex-1
  h-full
  justify-end

  lg:hidden
`;

const MobileNavTrigger = tw.a`
  text-gray-400
  no-underline
  p-2
  flex
  select-none
`;

const CallToActions = tw.div`
  -mb-4
  -mx-4
  bg-gray-50
  flex
  items-center
  mt-4
  p-4
  rounded-b-lg
`;

const CallToAction = tw(Button)`
  rounded-lg
  shadow-none
  !text-typography
  bg-transparent

  hover:shadow-none
  hover:translate-y-0
  hover:bg-black/5
`;

const products = {
  individuals: [
    {
      name: 'Bidali App',
      href: '/app',
      description: 'Pay, save, earn. All in one place.',
      icon: DevicePhoneMobile,
    },
    {
      name: 'Spend',
      href: 'https://spend.bidali.com',
      description: 'Buy gift cards, mobile airtime and more from thousands of brands around the world.',
      icon: Gift,
    }
  ],

  organizations: [
    // {
    //   name: 'Payments',
    //   href: '/business/payments',
    //   description: 'Accept touchless low-cost digital currency payments in minutes.',
    //   icon: Payments,
    // },
    {
      name: 'Branded Currency',
      href: '/business/branded-currency',
      description: 'Next generation branded stored value for global commerce.',
      icon: Currency,
    },
    {
      name: 'Rewards & Incentives',
      href: '/business/rewards',
      description: 'Increase sales, brand exposure and customer loyalty.',
      icon: Reward,
    },
    // {
    //   name: 'Treasury',
    //   href: '/business/treasury',
    //   description: 'Put your capital to work. Earn more on your idle cash.',
    //   icon: Vault,
    // }
  ]
};

const useCases = [
  {
    name: 'Businesses',
    href: '/solutions/businesses',
    description: 'Novel direct-to-customer CashDrop™ marketing.',
    icon: Business,
  },
  // {
  //   name: 'Government',
  //   href: '/solutions/government',
  //   description: 'Improved visibility into economic planning and targeted stimulus.',
  //   icon: BuildingGovernment,
  // },
  // { name:'Banks',
  //   href: '/solutions/banks',
  //   description: "Modern omni channel digital banking solutions.",
  //   icon: Bank
  // },
  // {
  //   name: 'Remittance',
  //   href: '/solutions/remittance',
  //   description: "Send and receive money instantly and without fees.",
  //   icon: GlobeAmericas,
  // },
  // {
  //   name: 'Humanitarian Aid',
  //   href: '/solutions/humanitarian-aid',
  //   description: 'Deliver humanitarian aid more efficiently.',
  //   icon: Heart
  // }
];

const callsToAction = [
  // { name: 'Watch Demo', href: '#', icon: Play },
  { name: 'Contact Sales', href: '/contact', icon: Phone },
];

const resources = [
  {
    name: 'Support',
    description: 'Get all of your questions answered in our FAQ or contact support.',
    href: 'https://support.bidali.com',
    icon: Lifebuoy,
  },
  {
    name: 'Security',
    description: 'Understand how we protect you, your data, and your money.',
    href: '/policies/security',
    icon: ShieldCheck
  },
  {
    name: 'Careers',
    description: 'Do you want to revolutionize how the world uses money? View our open positions.',
    href: '/careers',
    icon: Bookmark,
  },
  {
    name: 'About',
    description: 'Learn more about Bidali, our team and our mission.',
    href: '/about',
    icon: InformationCircle,
  }
];

// TODO (EK): Pull this dynamically
const recentPosts = [
  { id: 1, name: "Our CEO joins the Canadian Blockchain Consortium", href: '/blog/bidalis-ceo-named-head-of-cbc-fintech-committee-and-leading-an-industry-roundtable-on-crypto-asset-regulation-2020-04-04' },
  { id: 2, name: 'Our response to CSA Staff Notice 21-327', href: '/blog/bidalis-response-to-recent-csa-staff-guidance-2020-03-11' }
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const Trigger = ({ label, ...props }) => (
  <NavItem {...props}>{label} <ChevronDown className="ml-2" size={16} /></NavItem>
);

DesktopNav.Products = ({ navBarStyles = {} }) => (
  <Dropdown
    className="min-w-max"
    placement="bottom"
    trigger={<Trigger label="Products" style={navBarStyles.navItem} />}
  >
    <Grid columns={[1, 2]}>
      <Grid columns={1}>
        <Heading className="ml-2">For Individuals</Heading>
        {products.individuals.map((product, index) => (
          <Link className="hover:no-underline" key={index} href={product.href}>
            <List.Item
              className="max-w-xs min-h-[5.5rem] hover:bg-slate-50 p-2 rounded-lg"
              description={product.description}
              icon={<product.icon />}
              title={product.name}
              size="small"
            />
          </Link>
        ))}
      </Grid>
      <Grid columns={1}>
        <Heading className="ml-2">For Businesses</Heading>
        {products.organizations.map((product, index) => (
          <Link className="hover:no-underline" key={index} href={product.href}>
            <List.Item
              className="max-w-xs min-h-[5.5rem] hover:bg-slate-50 p-2 rounded-lg"
              description={product.description}
              icon={<product.icon />}
              title={product.name}
              size="small"
            />
          </Link>
        ))}
      </Grid>
    </Grid>
    <CallToActions>
      {callsToAction.map((action, index) => (
        <CallToAction
          icon={<action.icon size={16} />}
          key={index}
          to={action.href}
        >
          {action.name}
        </CallToAction>
      ))}
    </CallToActions>
  </Dropdown>
);

DesktopNav.Products.displayName = 'DesktopNav.Products';

DesktopNav.UseCases = ({ navBarStyles = {} }) => (
  <Dropdown
    className="min-w-max"
    placement="bottom"
    trigger={<Trigger label="Use Cases" style={navBarStyles.navItem} />}
  >
    <Grid columns={1}>
      {useCases.map((useCase, index) => (
        <Link className="hover:no-underline" key={index} href={useCase.href}>
          <List.Item
            className="max-w-xs min-h-[5.5rem] hover:bg-slate-50 p-2 rounded-lg"
            description={useCase.description}
            icon={<useCase.icon />}
            title={useCase.name}
            size="small"
          />
        </Link>
      ))}
    </Grid>
  </Dropdown>
);

DesktopNav.UseCases.displayName = 'DesktopNav.UseCases';

DesktopNav.More = ({ navBarStyles = {} }) => (
  <Dropdown
    className="min-w-[25rem]"
    placement="bottom"
    trigger={<Trigger label="More" style={navBarStyles.navItem} />}
  >
    <Grid columns={1}>
      {resources.map((useCase, index) => (
        <Link className="hover:no-underline" key={index} href={useCase.href}>
          <List.Item
            className="min-h-[5.5rem] hover:bg-slate-50 p-2 rounded-lg"
            description={useCase.description}
            iconProps={{ background: 'transparent', color: colors.brand.primary }}
            icon={<useCase.icon />}
            title={useCase.name}
            size="small"
          />
        </Link>
      ))}
    </Grid>
    <CallToActions className="flex-col items-start p-8">
      <Heading className="mb-4">Recent Posts</Heading>
      <Grid className="flex-1" columns={1}>
        {recentPosts.map(post => (
          <NavItem key={post.id} href={post.href}>
            {post.name}
          </NavItem>
        ))}
        <Link className="text-sm font-semibold" href="/blog">
          View all posts <span aria-hidden="true">&rarr;</span>
        </Link>
      </Grid>
    </CallToActions>
  </Dropdown>
);

DesktopNav.More.displayName = 'DesktopNav.More';

MobileNav.Products = () => (
  <Grid columns={[1]}>
    <Heading>For Individuals</Heading>
    <Grid columns={2}>
      {products.individuals.map((product, index) => (
        <Link className="hover:no-underline" key={index} href={product.href}>
          <List.Item
            className="max-w-xs"
            icon={<product.icon />}
            title={product.name}
            size="small"
          />
        </Link>
      ))}
    </Grid>
    <Heading>For Businesses</Heading>
    <Grid columns={2}>
      {products.organizations.map((product, index) => (
        <Link className="hover:no-underline" key={index} href={product.href}>
          <List.Item
            className="max-w-xs"
            icon={<product.icon />}
            title={product.name}
            size="small"
          />
        </Link>
      ))}
    </Grid>
  </Grid>
);

MobileNav.Products.displayName = 'MobileNav.Products';

MobileNav.UseCases = () => (
  <>
    <Heading className="mb-4 mt-8">Use Cases</Heading>
    <Grid columns={1}>
      {useCases.map((useCase, index) => (
        <Link className="hover:no-underline" key={index} href={useCase.href}>
          <List.Item
            className="max-w-xs"
            icon={<useCase.icon />}
            iconProps={{ background: 'transparent', color: colors.brand.primary }}
            title={useCase.name}
            size="small"
          />
        </Link>
      ))}
    </Grid>
  </>
);

MobileNav.UseCases.displayName = 'MobileNav.UseCases';

MobileNav.More = () => (
  <Grid className="bg-gray-50 -mx-4 -mb-4 rounded-b-lg p-4 mt-4" columns={2}>
    <NavItem href="https://docs.bidali.com">Developers</NavItem>

    {resources.map(item => (
      <NavItem key={item.name} href={item.href}>
        {item.name}
      </NavItem>
    ))}

    <NavItem href="/contact">Contact</NavItem>
    <NavItem href="/blog">Blog</NavItem>
  </Grid>
);

MobileNav.More.displayName = 'MobileNav.More';

const Navbar = () => {
  const { background, navBarStyles } = useNavbarBackground();

  return (
    <Container style={{ background, ...navBarStyles.container }}>
      <Content>
        <StyledLink href="/">
          <Logo style={navBarStyles.logo} src="https://assets.bidali.com/company/bidali/logo.png" width={134} height={32} alt="Bidali Logo" />
        </StyledLink>
        <DesktopNav>
          <DesktopNav.Products navBarStyles={navBarStyles} />
          {/* <DesktopNav.UseCases navBarStyles={navBarStyles} /> */}
          <NavItem href="https://docs.bidali.com/" target="_blank" rel="no-follow" style={navBarStyles.navItem}>
            Developers
          </NavItem>
          <DesktopNav.More navBarStyles={navBarStyles} />
        </DesktopNav>
        <MobileNav>
          <Dropdown
            placement="bottom-right"
            className="w-[calc(100vw-2rem)] max-w-xl"
            trigger={<MobileNavTrigger style={navBarStyles.navItem}><Bars3 size={24} /></MobileNavTrigger>}
          >
            <MobileNav.Products />
            {/* <MobileNav.UseCases /> */}
            <MobileNav.More />
          </Dropdown>
        </MobileNav>
      </Content>
    </Container>
  );
};

export default Navbar;
